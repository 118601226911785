<template>
  <div v-loading="loading.init" class="print-page">
    <div class="print-btn">
      <!-- <PrintButton
        v-if="waveOrderIds.length >0"
        :type="PRINT_BUTTON.type"
      /> -->
      <PrintButton
        v-model="temperatureCode"
        :type="TEMPERATURE_LAYER_BUTTON.type"
        :temperature-list="TEMPERATURE_LAYER"
        :before-print="beforePrint"
        multiple="true"
        @change="temperatureChanged"
      />
      <div v-if="waveOrderIds.length === 0" class="margin-24">
        是否解冻:
        <el-select
          v-model="isUnFreeze"
          placeholder="请选择"
          @change="init"
        >
          <el-option
            v-for="item in DEFROST"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div v-if="waveOrderIds.length > 0" class="margin-24">
        是否越库:
        <el-select
          v-model="crossDocking"
          placeholder="请选择"
          @change="init"
        >
          <el-option
            v-for="item in CROSSDOCKING"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div v-if="waveOrderIds.length > 0" class="margin-24">
        排序方式:
        <el-select
          v-model="pickOrderSortRule"
          placeholder="请选择"
          @change="init"
        >
          <el-option
            v-for="item in PICK_ORDER_SORT_RULE_LIST"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div v-if="waveOrderIds.length > 0" class="margin-24">
        库位类型:
        <el-select
          v-model="zoneType"
          placeholder="请选择"
          @change="init"
        >
          <el-option
            v-for="item in zoneTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>

      <div v-if="waveOrderIds.length === 0" class="position-right">
        <nh-button
          type="primary"
          @click="handleExport"
        >
          导出
        </nh-button>
      </div>
    </div>
    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <component
        :is="printList.component"
        :print-data="{...printList, shipperId}"
        :temperature-code="temperatureCode"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { YMD } from '@/constant/timeFormat';
import loadingMixin from '@thales/loading';
import { getDictionaryFromWms } from '@/api/base';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import PrintSign from './components/sign.vue';
import Normal from './normal/index.vue';
import { TEMPERATURE_LAYER_BUTTON, PRINT_BUTTON } from '../components/PrintButton/config';
import {
  TEMPERATURE_LAYER, DEFROST, CROSSDOCKING, PICK_ORDER_SORT_RULE_LIST,
} from './fileds';
import config from './config';
import Customized1 from './customized1/index.vue';
import {
  waveOrderPrint, pickExport, shuttleWaveOrderPrint, waveOrderPrintCount,
} from './api';

// 打印类型
const printType = 'PICK_PRINT';
export default {
  components: {
    PrintButton,
    PrintSign,
    Normal,
    Customized1,
  },
  mixins: [loadingMixin],
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      PRINT_BUTTON,
      TEMPERATURE_LAYER,
      DEFROST,
      CROSSDOCKING,
      config,
      waveOrderId: null,
      pickOrderSortRule: PICK_ORDER_SORT_RULE_LIST[0].value,
      PICK_ORDER_SORT_RULE_LIST,
      waveOrderIds: [],
      temperatureCode: [],
      isUnFreeze: null,
      crossDocking: null,
      zoneType: null,
      prints: [],
      printData: {},
      zoneTypeOptions: [],
      loading: {
        init: false,
      },
      shipperId: '',
    };
  },
  created() {
    this.waveOrderIds = this.$route.query.waveOrderIds
      ? JSON.parse(this.$route.query.waveOrderIds) : [];
    this.waveOrderId = this.$route.query.waveOrderId;
    this.shipperId = this.$route.query.shipperId;
    this.init();
    if (this.waveOrderIds.length > 0) {
      this.getDictionaryFromWms();
    }
  },
  methods: {
    async getDictionaryFromWms() {
      const dictionary = await getDictionaryFromWms();
      const filedMap = {};
      dictionary.forEach(({ dictionaryCode, dictionaryValues }) => {
        filedMap[dictionaryCode] = dictionaryValues.map((item) => ({
          value: item.code,
          label: item.name,
        }));
      });
      this.zoneTypeOptions = [{ value: null, label: '全部' }, ...filedMap.ZONE_TYPE_CODE];
    },
    getLayerName(val, arr) {
      const findAttr = arr.find((item) => item.temperatureLayerCode === val);
      return findAttr ? findAttr.temperatureLayerName : '';
    },
    async init() {
      this.prints = [];
      const {
        waveOrderIds,
        temperatureCode,
        crossDocking,
        pickOrderSortRule,
        zoneType,
        waveOrderId,
        isUnFreeze,
      } = this;
      if (waveOrderIds.length > 0) {
        const data = await shuttleWaveOrderPrint({
          waveOrderIds,
          temperatureLayer: this.trimSpace(JSON.parse(JSON.stringify(temperatureCode))).join(',') || null,
          crossDocking,
          pickOrderSortRule,
          zoneType,
        });
        this.prints = data.map((item) => ({
          ...item,
          planedPickTime: this.formatTime(item.planedPickTime),

          component: this.config.BATCH_PICKING,
        }));
      } else {
        const printData = await waveOrderPrint({
          waveOrderId,
          temperatureLayer: this.trimSpace(JSON.parse(JSON.stringify(temperatureCode))).join(',') || null,
          isUnFreeze,
        });
        printData.component = printData.component || this.config.DEFAULT; // 默认打印单为默认样式
        this.prints = [{ ...printData }];
      }
    },
    temperatureChanged(e) {
      e.forEach((item) => {
        if (item === '') {
          this.temperatureCode = ['', 'NORMAL', 'COLD', 'REFRIGERATE', 'CONSTANT'];
        }
      });
      this.init();
    },
    handleExport() {
      pickExport({
        waveOrderId: this.waveOrderId,
        temperatureLayer: this.trimSpace(JSON.parse(JSON.stringify(this.temperatureCode))).join(',') || null,
        isUnFreeze: this.isUnFreeze || null,
      });
    },
    async beforePrint() {
      const waveOrderIds = this.waveOrderIds.length > 0 ? this.waveOrderIds : [this.waveOrderId];
      const data = {
        waveOrderIds,
        printType,
      };
      await waveOrderPrintCount(data);
    },
    formatTime(time) {
      return time && moment(time).format(YMD);
    },
    trimSpace(array) {
      array.forEach((item, index) => {
        if (item === '' || item === null || typeof (item) === 'undefined') {
          array.splice(index, 1);
        }
      });
      return array;
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  max-width: 1123px;
  margin: 0 auto;
}

.print-contanier {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }
  }
}

.afterFooter {
  display: none;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }

  .position-right {
    margin-left: auto;
  }
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }
}
 </style>
