<template>
  <div class="print-page-sign">
    <div>
      <div>拣货员签字：</div>
    </div>
    <div>
      <div>单证员签字：</div>
    </div>
    <div>
      <div>复核员签字：</div>
    </div>
  </div>
</template>

 <style lang="scss" scoped>
.print-page-sign {
  margin-top: 3px;

  & > div {
    width: 32%;
    height: 30px;
    line-height: 30px;
    display: inline-block;

    & > div {
      display: inline-block;
      height: 100%;
      width: 100px;
      text-align: center;
    }

    &:first-child {
      & > div {
        border-left: none;
      }
    }
  }
}
 </style>
