import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:波次单打印
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12498
 */
export function waveOrderPrint(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order_print/pick', data);
}
/**
 * @description: 导出总拣单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15769
 */
export function pickExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/wave_order_print/pick/export', data, { fileName: '总拣单', extension: 'xlsx' });
}
/**
 * @description:专为穿梭提供的拣货信息接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16941
 */
export function shuttleWaveOrderPrint(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/chuan_suo/wave_pick/details', data);
}
/**
 * @description:打印次数
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17647
 */
export function waveOrderPrintCount(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/print_count', data);
}
