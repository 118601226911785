<template>
  <div>
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      class="print-contanier"
    >
      <print-header
        :hide-logo="true"
        :title="config.title"
        :show-ext="false"
        :barcode="printData.waveOrderNo"
        :print-config="printData"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }}
              <span class="font-size">{{ `${printData[item.value] || ''}` }}</span>
            </p>
          </div>
        </div>
      </div>
      <print-table
        class="table table-font-size"
        :headers="fileds.TABLECOLUMN"
        :list="table"
      />
      <div class="footer-sign">
        <div
          v-for="(row, index) in fileds.FOOTER_SIGN"
          :key="index"
          class="row"
        >
          <div
            v-for="(item, i) in row"
            :key="i"
            class="col"
            :style="{'flex': item.width}"
          >
            <p v-if="item.label" :style="{textAlign: item.align}">
              {{ item.label }}
            </p>
            <p v-else>
              {{ item.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import config from './config';
import fileds from './fileds';

export default {
  name: 'DeliveryListNormalNor',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    printData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config,
      fileds,
    };
  },
  computed: {
    tables() {
      return this.formatTable();
    },
  },
  methods: {
    formatTable() {
      const { goodsDetails } = this.printData;
      const tables = [];
      let tablePage = 0;
      let totalBigUnitAmount = 0;
      let totalSmallUnitAmount = 0;
      const tableRowsCount = config.maxRowsNumber;
      if (goodsDetails.length === 0) {
        tablePage += 1;
        tables.push([]);
      }
      goodsDetails.forEach((val, index) => {
        const item = {
          ...val,
          productDate: Moment.format(val.productDate, YMD),
          expireDate: Moment.format(val.expireDate, YMD),
          index: index + 1,
        };
        totalBigUnitAmount += item.bigUnitAmount * 1000;
        totalSmallUnitAmount += item.smallUnitAmount * 1000;
        if (index === tableRowsCount * tablePage) {
          tablePage += 1;
          tables.push([]);
        }
        tables[tablePage - 1].push(item);
      });
      const row = {
        locationCode: '合计',
        bigUnitAmount: totalBigUnitAmount / 1000,
        smallUnitAmount: totalSmallUnitAmount / 1000,
      };
      tables[tablePage - 1].push(row);
      return tables;
    },
  },
};
</script>
<style lang="scss" scoped>
.print-contanier {
  page-break-after: always;
  padding-bottom: 20px;
}

.row {
  display: flex;
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  p {
    padding: 0 10px;
  }
}

@media print {
  .print-contanier {
    padding-bottom: 0;
  }
}
</style>
<style lang="scss">
.child-tf {
  display: flex;

  div {
    flex: 1;
  }
}
</style>
