// 打印单公共信息配置
export default {
  PUBLIC(publicPrint) {
    let isOnlyOneStationCode = [];
    if (publicPrint.isOnlyOneStationCode) {
      isOnlyOneStationCode = [
        {
          label: '门店编码：',
          value: 'stationCode',
          width: 1,
        }, {
          label: '门店名称：',
          value: 'stationName',
          width: 1,
        }, {
          label: '备注：',
          value: 'remark',
          width: 1,
        },
      ];
    }
    return [
      [{
        label: '货主：',
        value: 'shipperName',
        width: 1,
      }, {
        label: '波次单号：',
        value: 'waveOrderNo',
        width: 1,
      }, {
        label: '最早提货时间：',
        value: 'earliestTakeDeliveryTime',
        width: 1,
      }],
      [...isOnlyOneStationCode],
    ];
  },
  tableHeaders: [
    { label: '序号', prop: 'index', width: '4.6%' },
    { label: '温层', prop: 'temperatureLayerName', width: '6.1%' },
    {
      label: '库位', prop: 'storageLocationCode', width: '7.6%', class: 'font-blod letter-spacing-1', maxLength: 10,
    },
    {
      label: '生产日期', prop: 'manufactureDate', width: '10%', class: 'letter-spacing-1',
    },
    {
      label: '货主货品编码', prop: 'shipperGoodsCode', width: '9.2%', class: 'font-blod letter-spacing-1', mediumLength: 7, maxLength: 9,
    },
    {
      label: '货品名称', prop: 'goodsName', mediumLength: 6, maxLength: 9,
    },
    {
      label: '货主', prop: 'shipperName', mediumLength: 6, maxLength: 9,
    },
    {
      label: '规格', prop: 'spec', width: '8%', maxLength: 10,
    },
    {
      label: '预约数量', prop: 'bookingNum', width: '8%', class: 'font-blod letter-spacing-1',
    },
    {
      label: '分配数量', prop: 'allocateNum', width: '8%', maxLength: 4,
    },
    { label: '实拣数量', prop: 'pickNum', width: '12.3%' },
  ],
};
