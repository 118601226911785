const TEMPERATURE_LAYER = [{
  temperatureLayerCode: '',
  temperatureLayerName: '全部',
}, {
  temperatureLayerCode: 'NORMAL',
  temperatureLayerName: '常温',
}, {
  temperatureLayerCode: 'COLD',
  temperatureLayerName: '冷冻',
}, {
  temperatureLayerCode: 'REFRIGERATE',
  temperatureLayerName: '冷藏',
}, {
  temperatureLayerCode: 'CONSTANT',
  temperatureLayerName: '恒温',
}];
const DEFROST = [{
  value: null,
  label: '全部',
}, {
  value: true,
  label: '解冻',
}, {
  value: false,
  label: '不解冻',
}];

const CROSSDOCKING = [{
  value: null,
  label: '全部',
}, {
  value: true,
  label: '是',
}, {
  value: false,
  label: '否',
}];

const PICK_ORDER_SORT_RULE_LIST = [{
  value: 'LOCATION_GOODS',
  label: '先库位,再货品',
}, {
  value: 'GOODS_LOCATION',
  label: '先货品,再库位',
}];

export {
  TEMPERATURE_LAYER, DEFROST, CROSSDOCKING, PICK_ORDER_SORT_RULE_LIST,
};
