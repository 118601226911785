<template>
  <div class="print-template">
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      class="print-contanier"
    >
      <print-header
        :order="publicPrint.waveOrderNo"
        :temperature-layer="temperatureCode"
        :title="config"
        :print-config="printData"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC(publicPrint)"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }} <span>{{ publicPrint[item.value] }}</span><el-button
                v-if="item.value==='waveOrderNo' && publicPrint.urgent"
                type="danger"
                round
                size="mini"
                style="margin-left: 10px;"
              >
                加急
              </el-button>
            </p>
          </div>
        </div>
      </div>
      <print-table
        class="table"
        :headers="tableHeaders"
        :list="table"
      />
      <print-sign class="sign" />
    </div>
  </div>
</template>

<script>
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import unitNumber, { BIG_NUMBER } from '@/utils/unitNumber';
import getTemperature from '@/utils/temperatureLayer';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import { TEMPERATURE_LAYER_BUTTON } from '../../components/PrintButton/config';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import fileds from './fileds';
import PrintSign from '../components/sign.vue';
import config from './config';

export default {
  components: {
    PrintHeader,
    PrintTable,
    PrintSign,
  },
  props: {
    temperatureCode: {
      default: '',
      type: [String, Number],
    },
    printData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      getTemperature,
      fileds,
      config,
      waveOrderId: null,
      tableHeaders: fileds.tableHeaders,
      publicPrint: {},
      tables: [],
    };
  },
  watch: {
    printData: {
      handler() {
        this.init();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const {
        customerNameList,
        waveOrderNo,
        earliestTakeDeliveryTime,
        isOnlyOneStationCode,
        stationName,
        stationCode,
        remarks,
        urgent,
      } = this.printData.header;
      this.publicPrint = {
        shipperName: [...new Set(customerNameList)].join('，'),
        waveOrderNo,
        earliestTakeDeliveryTime: moment.format(earliestTakeDeliveryTime),
        isOnlyOneStationCode,
        stationName,
        stationCode,
        remark: remarks.join(';') || '',
        urgent,

      };
      this.formatNormalRow(this.printData.body);
    },
    formatNormalRow(list) {
      this.tables = [];
      let tablePage = 0;
      let bigUnitBookingNum = 0;
      let smallUnitBookingNum = 0;
      let bigUnitAllocateNum = 0;
      let smallUnitAllocateNum = 0;
      const tableRowsCount = config.maxRowsNumber;
      list.forEach((val, index) => {
        const item = {
          ...val,
          index: index + 1,
        };
        item.manufactureDate = moment.format(item.manufactureDate, YMD);
        const amountBookingNum = unitNumber
          .calculate({ ...item, smallUnitNumber: item.allocateNum }, BIG_NUMBER);
        item.bookingNum = amountBookingNum.txt;
        bigUnitBookingNum += amountBookingNum.bigUnitNumber * 1000;
        smallUnitBookingNum += amountBookingNum.smallUnitNumber * 1000;
        const amountAllocateNum = unitNumber
          .calculate({ ...item, smallUnitNumber: item.allocateNum }, BIG_NUMBER);
        item.allocateNum = amountAllocateNum.txt;
        bigUnitAllocateNum += amountAllocateNum.bigUnitNumber * 1000;
        smallUnitAllocateNum += amountAllocateNum.smallUnitNumber * 1000;
        item.temperatureLayerName = getTemperature(item.temperatureLayer, {}, item.isUnFreeze);
        if (item.pickNum) {
          item.pickNum = unitNumber.calculate({ ...item, smallUnitNumber: item.pickNum }).txt;
        } else {
          item.pickNum = `&emsp;${item.bigUnit}&emsp;&emsp;${item.smallUnit}`;
        }
        if (index === tableRowsCount * tablePage) {
          tablePage += 1;
          this.tables.push([]);
        }
        this.tables[tablePage - 1].push(item);
      });
      const row = {
        storageLocationCode: '总计',
        bookingNum: `${bigUnitBookingNum / 1000}整${smallUnitBookingNum / 1000}零`,
        allocateNum: `${bigUnitAllocateNum / 1000}整${smallUnitAllocateNum / 1000}零`,
      };
      this.tables[tablePage - 1].push(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.print-contanier {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }
  }
}

.afterFooter {
  display: none;
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }
}
 </style>
