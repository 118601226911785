import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';

export default {
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '负责人:',
      value: 'responsiblePerson',
      width: 1,
    }, {
      label: '计划拣货时间:',
      value: 'planedPickTime',
      width: 1,
    }, {
      label: '出库单号:',
      value: 'waveOrderNo',
      width: 1,
    }],
    [{
      label: '车号车次:',
      value: 'plateNumber',
      width: 1,
    }, {
      label: '装车顺序:',
      value: 'loadingOrder',
      width: 1,
    }, {
      label: '配送点:',
      value: 'deliverStation',
      width: 1,
    }],
    [{
      label: '总件数:',
      value: 'totalDefaultUnitAmount',
      width: 1,
    }, {
      label: '备注:',
      value: 'remark',
      width: 1,
    },
    {
      width: 1,
    }],
    [{
      label: '客户编号:',
      value: 'shipperCode',
      width: 1,
    }, {
      label: '客户名称:',
      value: 'shipperName',
      width: 1,
    }, {
      width: 1,
    }],
  ],
  TABLECOLUMN: [
    {
      label: '商品代码', prop: 'shipperGoodsCode', width: '110px', class: 'letter-spacing-1',
    },
    {
      label: '商品名称', prop: 'goodsName', width: '110px',
    },
    {
      label: '商品规格', prop: 'specification', width: '100px',
    },
    { label: '生产日期', prop: 'productDate', width: '120px' },
    {
      label: '保质期', prop: 'expireDate', width: '120px',
    },
    {
      label: '拣货库位', prop: 'locationCode', width: '100px',
    },
    {
      label: '整箱数', prop: 'bigUnitAmount', width: '80px',
    },
    {
      label: '零包数', prop: 'smallUnitAmount', width: '80px',
    },
  ],
  // 签名
  FOOTER_SIGN: [
    [{
      label: '单证员',
      width: 2,
      align: 'center',
    }, {
      value: '',
      width: 2,
    }, {
      label: '拣货人',
      width: 2,
      align: 'center',
    }, {
      value: '',
      width: 2,
    }, {
      label: '打印时间',
      width: 2,
      align: 'center',
    }, {
      value: moment(new Date()).format(YMDHMS),
      width: 2,
    }],

  ],
};
